import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import { NumberedList } from "../components/NumberedList"
import GetStarted from "../components/CTA/GetStarted"
import TheaterVideo from "@bit/azheng.joshua-tree.theater"

const SurgicalCenter = ({ data, pageContext, location }) => {
  const post = data.allSurgicalCentersJson.nodes[0]
  const language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout className="surgical-center" language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="p-section top">
          <div className="columns max-container">
            <div className="column">
              <div className="icon-with-heading">
                <div className="icon-with-heading__icon">
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={post.hero.imageId}
                    responsive
                    width="auto"
                  />
                </div>
                <h1>{post.hero.heading}</h1>
              </div>

              <MarkdownViewer markdown={post.hero.blurb} />
            </div>
          </div>
        </div>

        {post.mainVideo.imageId && (
          <div className="columns has-text-centered surgical-main-theater-image">
            <div className="column is-full-width">
              <TheaterVideo
                buttonClass="contained"
                videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
                language={language}
              >
                <NuvoImage
                  cloudName="nuvolum"
                //   wrapperClassName="show-desktop-only"
                  publicId={post.mainVideo.imageId}
                  useAR
                  width="auto"
                  responsive
                />
              </TheaterVideo>
            </div>
          </div>
        )}

        <div className="p-section color-back">
          <div className="max-container">
            <h2 className="h3-style mt-0 mb-2">
              {post.numberedBlurbs.heading}
            </h2>
            <NumberedList list={post.numberedBlurbs.list} />
          </div>
        </div>

        <div className="p-section">
          <div className="max-container">
            <GetStarted
              sideColumnIs={0}
              headingSideColumnIs={0}
              centerHeading
              centerText
              heading={post.getStarted.heading}
              paragraph={post.getStarted.blurb}
              buttons={post.getStarted.buttons}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query SurgicalCenterPage($title: String!) {
    allSurgicalCentersJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          blurb
          imageId
        }
        numberedBlurbs {
          heading
          list {
            heading
            blurb
          }
        }
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        getStarted {
          colorBack
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default SurgicalCenter
