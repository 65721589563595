import React from 'react'
import classNames from 'classnames'

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const NumberedList = ({ list=[], whiteCircles }) => {

    const numberClasses = classNames({
        "numbered-list__item__number": true,
        "item-number--solid": !whiteCircles 
    })
    return (
        <div className="numbered-list">
            {list.map((item, i) => (
                <div key={i} className="numbered-list__item">
                    <div className={numberClasses}>
                        <span>{i + 1}</span>
                    </div>
                    <div className="numbered-list__item__content">
                        <h5>{item.heading}</h5>
                        <MarkdownViewer markdown={item.blurb} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default NumberedList
